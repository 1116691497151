import React from "react";
import dayjs from "dayjs";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "../../style/order.css";

const DateTimePickerComponent = (props) => {
  const formik = useFormikContext();
  const dateNow = dayjs();

  ///We only allow ordering for certain time
  const shouldDisableTime = (value, view) =>
    (view === "hours" && value.hour() > 15 && value.hour() < 18) ||
    (view === "hours" && value.hour() > 20) ||
    (view === "hours" && value.hour() < 12);

  //We only allow for certain dates
  const shouldDisableDate = (date) => {
    const day = date.day();

    return day === 0;
  };

  const lunchStart = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Field
          className={props.className}
          {...props}
          as={DateTimePicker}
          id='dateTime'
          name='dateTime'
          inputVariant='outlined'
          disablePast
          ampm={false}
          shouldDisableTime={shouldDisableTime}
          shouldDisableDate={shouldDisableDate}
          onChange={(value) => formik.setFieldValue("dateTime", value.$d)}
        />
        <ErrorMessage
          className='error-message'
          name='dateTime'
          component='div'
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateTimePickerComponent;
