import React, { useEffect, useState, useContext, useRef } from "react";
import { ItemContext } from "../../context/ItemContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import { Typography, Card, CardContent, Box, IconButton } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import DropdownList from "./DropdownList";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Popup from "./Popup";
import RadioComponent from "./RadioGroupComponent";
import CheckboxGroup from "./CheckboxGroup";
import CheckboxGroupMultiple from "./CheckBoxMultiple";
import "../../style/item-details.css";

const ItemDetails = () => {
  const updatedItemsRef = useRef(null); // Initialize the ref with null
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [option, setOption] = useState([]);
  const [optionPrice, setOptionPrice] = useState(0);

  const [showPopup, setShowPopup] = useState(false);

  const { submitOrder, userDetails, totalOption } = useContext(ItemContext);

  const navigate = useNavigate();

  const routeChangeCheckout = () => {
    submitOrder(updatedItemsRef.current, totalPrice, userDetails);
    let path = `/order`;
    navigate(path);
  };

  const routeChangeBack = () => {
    let path = `/menu`;
    navigate(path);
  };

  const onItemClick = (item, isSelected) => {
    setSelectedItems((prevSelectedItems) => {
      const existingItem = prevSelectedItems.find(
        (selectedItem) => selectedItem.item.id === item.id
      );

      let updatedItems;

      if (isSelected) {
        if (existingItem) {
          // Item already exists in the selected items, increase its quantity
          updatedItems = prevSelectedItems.map((selectedItem) =>
            selectedItem.item.id === item.id
              ? {
                  ...selectedItem,
                  quantity: selectedItem.quantity + 1,
                }
              : selectedItem
          );
        } else {
          // Item doesn't exist in the selected items, add it with quantity 1
          updatedItems = [...prevSelectedItems, { item, quantity: 1 }];
        }
      } else {
        if (existingItem && existingItem.quantity > 0) {
          // Item exists in the selected items, decrease its quantity
          updatedItems = prevSelectedItems.map((selectedItem) =>
            selectedItem.item.id === item.id
              ? { ...selectedItem, quantity: selectedItem.quantity - 1 }
              : selectedItem
          );
        }
      }

      return updatedItems || prevSelectedItems;
    });
  };

  useEffect(() => {
    fetchData(`/item/${id}`)
      .then((response) => {
        setItem(response.item);
        onItemClick(response.item, true);
      })
      .catch((error) => console.error("Error fetching item details:", error));
  }, [id]);

  useEffect(() => {
    console.log("Item list ", item);
    console.log("Option", option);
    const localOptionsTotal = option.reduce(
      (total, option) => total + parseFloat(option.price),
      0
    );
    setOptionPrice(localOptionsTotal);
  }, [totalOption, item, showPopup]);

  if (item.length === 0) {
    return <div>Loading...</div>; // You can show a loading spinner or message here
  }

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
    onItemClick(item, true);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      onItemClick(item, false);
    } else {
      alert("You cannot select less than 1 item");
    }
  };

  const handleOptionChange = (option, selected) => {
    if (selected) {
      setOption((prevOptions) => [...prevOptions, option]);
    } else {
      setOption((prevOptions) =>
        prevOptions.filter((opt) => opt.name !== option.name)
      );
    }
  };

  const handleOrderSubmit = () => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.map((item) => ({
        item: {
          ...item.item,
          options: option,
          quantity: quantity,
        },
      }));
      updatedItemsRef.current = updatedItems; // Store the updatedItems in the ref
      console.log("Updated items with options:", updatedItems);
      return updatedItems;
    });

    if (selectedItems.length === 0) {
      alert("Order is empty, please click plus button to add to order");
    } else {
      //Setting on context

      console.log("totalPrice!!!!", totalPrice);
      setShowPopup(true);
    }
  };

  const totalQuantity = selectedItems.reduce(
    (acc, selectedItem) => acc + selectedItem.quantity,
    0
  );

  const totalPrice = selectedItems.reduce(
    (acc, selectedItem) =>
      acc +
      (selectedItem.item.amount / 100) * selectedItem.quantity +
      optionPrice / 100,
    0
  );

  const handleBack = () => {
    setShowPopup(true);
    routeChangeBack();
  };

  return (
    <Card className='item-details' sx={{ cursor: "pointer", mb: 32 }}>
      <CardContent className='menu-item'>
        <Typography variant='h8' component='div' gutterBottom>
          {item.imageUrl ? (
            <img src={item.imageUrl} alt='logo' style={{ width: "50%", height: "30%", borderRadius: "50px"  }} />
          ) : (
            <></>
          )}
        </Typography>
        <Typography variant='h5' component='div' gutterBottom>
          {item.name}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {item.description}
        </Typography>
        <Typography variant='h6' component='div' sx={{ marginTop: "16px" }}>
          Price: ${parseFloat(item.amount / 100)} each
        </Typography>
        <Typography variant='h6' component='div' sx={{ marginTop: "16px" }}>
          Total amount of options: ${optionPrice / 100}
        </Typography>
        <CardContent className='option-list'>
          {/* Card content */}
          <Box sx={{ minWidth: 120 }}>
          <InputLabel id='demo-simple-select-label'>Base</InputLabel>
            <CheckboxGroup
              priceOfItem={item.amount}
              onTotalOptionChange={handleOptionChange}
              options={
                item.modifiers
                  .filter((item) => item.group == "Donburi TYPE")
                  .flatMap((item) => {
                    console.log(item);
                    return item.details;
                  }) || []
              }
            />
          </Box>
        </CardContent>
        <CardContent className='option-list'>
          {/* Card content */}
          <Box sx={{ minWidth: 100 }}>
          <InputLabel id='demo-simple-select-label'>Extra</InputLabel>
            <CheckboxGroupMultiple
              onTotalOptionChange={handleOptionChange}
              options={
                item.modifiers
                  .filter((item) => item.group == "Option")
                  .flatMap((item) => {
                    return item.details;
                  }) || []
              }
            />
          </Box>
        </CardContent>
        <CardContent className='option-list'>
          {/* Card content */}
          <Box sx={{ minWidth: 120 }}>
            <InputLabel id='demo-simple-select-label'>Protein</InputLabel>
            <CheckboxGroup
              onTotalOptionChange={handleOptionChange}
              priceOfItem={item.amount}
              options={
                item.modifiers
                  .filter(
                    (item) =>
                      item.group == "TYPE" ||
                      item.group == "TYPE 1" ||
                      item.group == "Type"
                  )
                  .flatMap((item) => {
                    console.log(item);
                    return item.details;
                  }) || []
              }
            />
          </Box>
        </CardContent>
        <CardContent className='option-list'>
          {/* Card content */}
          <Box sx={{ minWidth: 120 }}>
            <InputLabel id='demo-simple-select-label'>Special Sauce</InputLabel>
            <CheckboxGroup
              onTotalOptionChange={handleOptionChange}
              priceOfItem={item.amount}
              options={
                item.modifiers
                  .filter((item) => item.group == "Special Sauce")
                  .flatMap((item) => {
                    console.log(item);
                    return item.details;
                  }) || []
              }
            />
          </Box>
        </CardContent>
      </CardContent>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton aria-label='remove' onClick={handleDecreaseQuantity}>
          <RemoveIcon />
        </IconButton>
        <Typography
          variant='body1'
          component='div'
          sx={{ margin: "0 8px" }}
          style={{ color: "black" }}
        >
          {quantity}
        </Typography>
        <IconButton aria-label='add' onClick={handleIncreaseQuantity}>
          <AddIcon />
        </IconButton>
      </Box>
      <h3>Total Price: ${totalPrice || 0} </h3>
      <h3>Total Quantity: {totalQuantity || 0}</h3>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant='contained'
          size='large'
          sx={{
            borderRadius: 50,
            mb: 24,
          }}
          onClick={handleBack}
        >
          Back to menu
        </Button>
        <Button
          variant='contained'
          size='large'
          sx={{
            borderRadius: 50,
            mb: 24,
          }}
          style={{
            position: "sticky",
            color: "white",
            backgroundColor: "green",
          }}
          onClick={() => {
            handleOrderSubmit();
          }}
        >
          Add to Cart
        </Button>
      </Box>
      <Popup
        open={showPopup}
        onClose={() => setShowPopup(false)}
        onKeepShopping={handleBack}
        onGoToCart={routeChangeCheckout}
      />
    </Card>
  );
};

export default ItemDetails;
