import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Menu from "./pages/Menu";
import OrderForm from "./pages/OrderForm";
import Success from "./pages/Success";
import "../style/navigation.css";
import ViewMenu from "./pages/View-Menu";
import ItemDetails from "./pages/ItemDetails";
import { ReactComponent as Logo } from "../logo/KGLOGOFINAL-1.svg";

function App() {
  const [menuActive, setMenuActive] = useState(false);


  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <Router>
      <nav className='navbar'>
        <div
          className={`burger-menu ${menuActive ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
        <Link to = "/">
               <Logo className='logo'/>
        </Link>
        <ul className={`horizontal-menu ${menuActive ? "active" : ""}`}>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/about'>About</Link>
          </li>
          <li>
            <Link to='/view-menu'>View Menu</Link>
          </li>
          <li>
            <Link to='/menu'>Order Online</Link>
          </li>
          <li>
            <Link to='/order'>Cart</Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/menu' element={<Menu />}></Route>
        <Route path='/order' element={<OrderForm />}></Route>
        <Route path='/success' element={<Success />}></Route>
        <Route path='/view-menu' element={<ViewMenu />}></Route>
        <Route path='/item/:id' element={<ItemDetails />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
