import React, { useState, useContext } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ItemContext } from "../../context/ItemContext";

const CheckboxGroupMultiple = ({
  options,
  priceOfItem,
  onTotalOptionChange,
}) => {
  const { cart, totalPrice, totalOption, setTotalOption } =
    useContext(ItemContext);
  const [selectedValues, setSelectedValues] = useState([]);
  const [itemPrice, setItemPrice] = useState(Number(priceOfItem));

  const handleCheckboxChange = (value, optionObj) => {
    const isChecked = selectedValues.includes(value);
    const selectedOption = options.find((option) => option.name === value);

    let newSelectedValues;
    if (isChecked) {
      // Deselecting an option
      newSelectedValues = selectedValues.filter((val) => val !== value);
      setTotalOption(totalOption - Number(selectedOption.price));
      // Call the onTotalOptionChange function to update options in parent component
      onTotalOptionChange(optionObj, false);
    } else {
      // Selecting an option
      newSelectedValues = [...selectedValues, value];
      setTotalOption(totalOption + Number(selectedOption.price));
      // Call the onTotalOptionChange function to update options in parent component
      onTotalOptionChange(optionObj, true);
    }
    // Uplifting the state
    setSelectedValues(newSelectedValues);
  };

  return (
    <FormGroup style={{flexDirection: "row"}}>
      {options.map((option) => (
        <FormControlLabel
          style={{ color: "black" }}
          key={option.id}
          control={
            <Checkbox
              checked={selectedValues.includes(option.name)}
              onChange={() => handleCheckboxChange(option.name, option)}
            />
          }
          label={`${option.name} - $${option.price / 100}`}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxGroupMultiple;
