import React, { useState, useContext, useEffect } from "react";

import MenuItem from "./MenuItem";
import { useNavigate } from "react-router-dom";
import "../../style/menu.css";
import { fetchData } from "../api";
import { ItemContext } from "../../context/ItemContext";

const Menu = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [menuItems, setMenuItem] = useState([]);

  const { submitOrder, userDetails } = useContext(ItemContext);

  const navigate = useNavigate();

  const routeChange = () => {
    let path = `/order`;
    navigate(path);
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetchData("/items");
        if (!response.ok) {
          throw new Error("Request failed from Menu.js");
        }
        const data = await response;
        console.log("data", data);
        setMenuItem(data.items.filter((item) => item));
        console.log(data);
        return data;
      } catch (error) {
        console.log(error);
      }
    };

    fetchItems()
      .then((res) => {
        console.log("Res from useEffect", res);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleItemClick = (item, isSelected) => {
    setSelectedItems((prevSelectedItems) => {
      const existingItem = prevSelectedItems.find(
        (selectedItem) => selectedItem.item.id === item.id
      );

      let updatedItems;

      if (isSelected) {
        if (existingItem) {
          // Item already exists in the selected items, increase its quantity
          updatedItems = prevSelectedItems.map((selectedItem) =>
            selectedItem.item.id === item.id
              ? { ...selectedItem, quantity: selectedItem.quantity + 1 }
              : selectedItem
          );
        } else {
          // Item doesn't exist in the selected items, add it with quantity 1
          updatedItems = [...prevSelectedItems, { item, quantity: 1 }];
        }
      } else {
        if (existingItem && existingItem.quantity > 0) {
          // Item exists in the selected items, decrease its quantity
          updatedItems = prevSelectedItems.map((selectedItem) =>
            selectedItem.item.id === item.id
              ? { ...selectedItem, quantity: selectedItem.quantity - 1 }
              : selectedItem
          );
        }
      }

      return updatedItems || prevSelectedItems;
    });
  };

  const totalQuantity = selectedItems.reduce(
    (acc, selectedItem) => acc + selectedItem.quantity,
    0
  );

  const totalPrice = selectedItems.reduce(
    (acc, selectedItem) =>
      acc + (selectedItem.item.amount / 100) * selectedItem.quantity,
    0
  );

  return (
    <div>
      <h1>Menu</h1>
      <h2>メニュー</h2>
      <div className='menu-container'>
        <p>Online order coming soon</p>
        {/* {menuItems.map((item) => (
          <MenuItem
            className='menu-item'
            key={item.id}
            item={item}
            onItemClick={handleItemClick}
          />
        ))} */}
      </div>
    </div>
  );
};

export default Menu;
