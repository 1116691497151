import React from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";

import MenuImage from "../../images/KG_menu_COL_Aug2023_OL+-1.png";
function ViewMenu() {
  return (
    <div>
      <h2>Call us</h2>
      <h2>お問い合わせ</h2>
      <a href='tel:6493078080 '>
        <h3> +09-307-8080</h3>
        <PhoneEnabledIcon fontSize='large' color='action' />
      </a>
      <p> *Prices are subjected to change</p>
      <div>
       
        <img src={MenuImage} alt='logo' style={{ width: "50%" }} />
      </div>
    </div>
  );
}

export default ViewMenu;
