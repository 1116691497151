import React, { useEffect, useContext, useState } from "react";
import { Field, ErrorMessage, FormikProvider, useFormik } from "formik";
import { Button, TextField, Grid, Typography } from "@material-ui/core";
import Input from "@mui/joy/Input";
import OrderCard from "./OrderCard";
import DateTimePickerComponent from "../forms/DatePicker";
import { postData } from "../api";
import { ItemContext } from "../../context/ItemContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import "../../style/order.css";
import * as Yup from "yup";

// import dotenv from "dotenv";
// dotenv.config();

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "60vh", // Ensure the form takes at least 80% of viewport height
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    alignSelf: "flex-end", // Align the button to the bottom
    marginTop: theme.spacing(2),
  },
}));


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const OrderForm = () => {
  const [localUser, setLocalUser] = useState({});

  const classes = useStyles();

  const initialValues = {
    name: "",
    lastName: "",
    email: "",
    phone: "",
    dateTime: null,
    items: [],
    specialInstructions: "",
    amount: 0,
  };

  const { totalPrice, setUserDetails, userDetails, cart, setTotalOption } =
    useContext(ItemContext);

  useEffect(() => {
    //Listening in the localUser state
    console.log("Local user updated", localUser);
    console.log("Cart", cart);

    //Set the state to the context
    setUserDetails(localUser);
    sessionStorage.setItem("kg-user", JSON.stringify(localUser));
  }, [localUser]);

  const handleSubmit = async (values) => {
    setLocalUser(values);
    const amountInCents = totalPrice * 100;
    console.log("Amount in cents:", amountInCents);
    const body = { amount: amountInCents, items: cart };

    postData("/order", body)
      .then((response) => {
        if (response.url) {
          console.log("Nofy", body);
          if (sessionStorage.getItem("kg-items")) {
            sessionStorage.removeItem("kg-items");
          }
          // if (sessionStorage.getItem("kg-user")) {
          //   sessionStorage.removeItem("kg-user");
          // }
          console.log("cart", cart);
          sessionStorage.setItem("kg-items", JSON.stringify(cart));
          window.location.href = response.url;
        } else {
          throw new Error("Error creating payment intent");
        }
      })
      .then((res) => {
        console.log(res);
        // const posBody = { res };
        // postData("/payment-success", postBody);
      })
      .catch((error) => {
        // Handle network error
        console.error("Network error occurred", error);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("*First name is required"),
    lastName: Yup.string().required("*Last name is required"),
    email: Yup.string()
      .email("*Invalid email address")
      .required("*Email is required"),
    phone: Yup.number()
      .integer()
      .typeError("Please enter a valid phone number")
      .required("*Phone number is required"),
    dateTime: Yup.date()
      .typeError("Please enter a valid date")
      .required("*Date and time required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    // Add validation schema or validation functions if needed
  });

  return (
    <Elements stripe={stripePromise}>
    <div className="order-container">
      <OrderCard />
      <h1>Pick Up Order Details</h1>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field
                as={TextField}
                name='name'
                label='Name'
                variant='outlined'
                className={classes.field}
                fullWidth
                {...formik.getFieldProps("name")}
                error={formik.touched.name && formik.errors.name}
              />
              <ErrorMessage
                name='name'
                component='div'
                className='error-message'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                as={TextField}
                name='lastName'
                label='Last Name'
                variant='outlined'
                className={classes.field}
                fullWidth
                {...formik.getFieldProps("lastName")}
                error={formik.touched.lastName && formik.errors.lastName}
              />
              <ErrorMessage
                name='lastName'
                component='div'
                className='error-message'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name='phone'
                label='Phone'
                variant='outlined'
                className={classes.field}
                fullWidth
                {...formik.getFieldProps("phone")}
                error={formik.touched.phone && formik.errors.phone}
              />
              <ErrorMessage
                name='phone'
                component='div'
                className='error-message'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name='email'
                label='Email'
                variant='outlined'
                className={classes.field}
                fullWidth
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
              />
              <ErrorMessage
                name='email'
                component='div'
                className='error-message'
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePickerComponent />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name='specialInstructions'
                label='Note: e.g. Allergies'
                variant='outlined'
                size='medium'
                className={classes.field}
                fullWidth
                multiline
                rows={4}
                {...formik.getFieldProps("specialInstructions")}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            variant='contained'
            size="large"
            style={{
              position: "sticky",
              color: "white",
              backgroundColor: "green",
            }}
            className={classes.submitButton}
          >
            Place Order
          </Button>
        </form>
      </FormikProvider>
    </div>
  </Elements>
  );
};

export default OrderForm;
