import React from "react";
import logo from "./logo.svg";
import Navigation from "./components/Navigation";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Footer from "./components/footer";

import "./App.css";
import { ItemProvider } from "./context/ItemContext";

function App() {
  return (
    <ItemProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className='App'>
          <Navigation />
          <header className='App-header'></header>
          <Footer />
        </div>
      </MuiPickersUtilsProvider>
    </ItemProvider>
  );
}

export default App;
