import React, { useEffect, useContext, useState } from "react";
import { postData, fetchData } from "../api";

const Success = () => {
  //Get the item input from the sessions storage
  const orderItemsInput = JSON.parse(sessionStorage.getItem("kg-items"));
  const user = JSON.parse(sessionStorage.getItem("kg-user"));

  const [orderPlaces, isOrderPlaced] = useState(false);

  //Create the object to match the required order
  const convertArrayToOrder = () => {
    const order = {
      order: {
        locationId: "LA38CNEP561P9",
        lineItems: orderItemsInput.map((item) => {
          const { name, amount, currency, id, options, phone } = item.item;
          console.log("Itemss", item);
          const quantity = item.quantity;
          const note = user.specialInstructions;
          return {
            name: name + " " + phone,
            quantity: quantity ? String(quantity) : "1",
            note: note
              ? "Special Instruction: " + note
              : "Special Instruction: ",
            itemType: "ITEM",
            basePriceMoney: {
              amount: 0,
              currency: "NZD",
            },
            modifiers: options.map((option) => {
              return {
                catalog_object_id: options.id,
                name: option.name,
                basePriceMoney: {
                  amount: 0,
                  currency: "NZD",
                },
              };
            }),
          };
        }),
        fulfillments: [
          {
            type: "PICKUP",
            state: "PROPOSED",
            pickupDetails: {
              recipient: {
                displayName: user.name + " " + user.lastName,
                emailAddress: user.emailAddress,
                phoneNumber: user.phoneNumber,
              },
              pickupAt: user.dateTime,
              scheduleType: "SCHEDULED",
            },
          },
        ],
      },
    };
    return order;
  };

  const order = convertArrayToOrder();
  const createOrder = async () => {
    try {
      const body = order;
      console.log(order);
      const response = await postData("/create-order-square", body);
      if (response.orderId) {
        isOrderPlaced(true);
        //Uncomment these before going live as they will remove the session to stop spamming of orders
        // sessionStorage.removeItem("kg-items");
        // sessionStorage.removeItem("kg-user");
      } else {
        // Order creation failed
        throw new Error("Failed to create order");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isOrderPlaced) {
      return <p>Loading....</p>;
    }
    createOrder();
  }, []);

  return (
    <div>
      {orderPlaces && (
        <h1>
          Order is successful, please go to store to pick up at the desired
          time.
        </h1>
      )}
    </div>
  );
};

export default Success;
