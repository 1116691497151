import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import "../../style/about.css";

function About() {
  return (
    <div>
      <h1>About Us</h1>
        <p className="desc">
        
        We embarked on our journey with a passion to introduce the rich heritage of Japanese donburi and extend the vibrant tapestry of Japanese culinary traditions to the community of New Zealand.
        <br></br>
        <br></br>
         Our dedicated team eagerly awaits the opportunity to greet you at our restaurant, where we aspire to cultivate an environment of serenity and warmth for all our guests.
        <br />
        <br></br>
        <a href='tel:6493078080'>Ph:09 307 8080 </a>
        <br />
      </p>
      <a
        href='https://www.instagram.com/kg_jp_restaurant/'
        target='_blank'
        color='primary'
      >
        <InstagramIcon fontSize='large' color='action' />
      </a>
    </div>
  );
}

export default About;
