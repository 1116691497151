import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Card, CardContent, Box } from "@mui/material";
import "../../style/menu-item.css";

const MenuItem = ({ item, onItemClick }) => {
  const [quantity, setQuantity] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Donburi Type");
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`/item/${item.id}`);
  };

  return (
    <Card
      className="menu-item-card"
      sx={{
        cursor: "pointer",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "0.3s",
        "&:hover": {
          boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
        },
      }}
      onClick={onClickHandler}
    >
      <CardContent className="menu-item" sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: "1 0 auto", position: "relative" }}>
          <img
            src={item.imageUrl}
            alt={item.name}
            className="item-img"
            style={{ borderRadius: "8px", width: "100%", height: "200px", objectFit: "cover" }}
          />
          <Box sx={{ flex: "0 0 auto" }}>
          <Typography variant="h6" component="div" gutterBottom>
            {item.name}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {item.description}
          </Typography>
          </Box>
          <Box
            sx={{
              position: "static",
              background: "#fff",
              width: "20%",
              margin: "0 auto",
              padding: "4px 8px",
              marginTop: "12px",
              borderRadius: "8px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="body2" color="text.primary" sx={{ fontSize: "14px" }}>
              ${parseFloat(item.amount / 100)}
            </Typography>
          </Box>
        </Box>
     
      </CardContent>
    </Card>
  );
};

export default MenuItem;
