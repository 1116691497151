import React, { createContext, useState, useEffect } from "react";
import Stripe from "stripe";

const ItemContext = createContext();
const API_KEY = process.env.API_KEY;
const stripe = new Stripe(API_KEY);

const ItemProvider = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [totalOption, setTotalOption] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    // Calculate the total price whenever the cart changes
    console.log("Cart", cart);
    const cartTotal = cart.reduce((acc, item) => {
      console.log("Opt", item);
      const quantity = item.item.quantity > 1 ? item.item.quantity : 1;
      const optionsTotal = item.item.options.reduce(
        (total, option) => parseFloat(total) + parseFloat(option.price),
        0
      );
      return acc + optionsTotal + parseFloat(item.item.amount) * quantity;
    }, 0);

    console.log("cartTotal", cartTotal);
    const total = cartTotal / 100;
    console.log("context options", totalOption);
    console.log("context total", total);
    setTotalPrice(total); // Set the total price with two decimal places
  }, [cart, totalOption, totalPrice]);

  const submitOrder = (items, totalPrice, userInfo) => {
    console.log("submit order context", userInfo);
    setSelectedItems([]);
    setUserDetails(userInfo);
    setTotalPrice(totalPrice);
    setCart((prevCart) => [...prevCart, ...items]);
    // Perform any additional processing or validation here
    // Example: Display a console log with the selected item details
  };

  return (
    <ItemContext.Provider
      value={{
        selectedItems,
        totalPrice,
        submitOrder,
        setSelectedItems,
        totalOption,
        setTotalOption,
        setTotalPrice,
        userDetails,
        setUserDetails,
        cart,
        setCart,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};

export { ItemContext, ItemProvider };
