import React from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";

const Popup = ({ open, onClose, onKeepShopping, onGoToCart }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>What would you like to do?</DialogTitle>
      <DialogContent>
        <Button onClick={onKeepShopping} variant='contained' color='info'>
          Keep Shopping
        </Button>
        <Button onClick={onGoToCart} variant='contained' color='success'>
          Go to Cart
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
