const BASE_URL = process.env.REACT_APP_BASE_URL;
// const PORT = 4000;

export const postData = (endpoint, body, options) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    ...options,
  };
  // return fetch(`${BASE_URL}:${PORT}${endpoint}`

  return fetch(`${BASE_URL}${endpoint}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Request failed");
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const fetchData = (endpoint, options) => {
  return fetch(`${BASE_URL}${endpoint}`, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Request failed from API js");
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
