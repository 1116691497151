import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Menu from "./Menu";
import Carousel from "./Carousel";
import { InstagramEmbed } from "react-social-media-embed";

function Home() {
  return (
    <div className='home'>
      <h1> Welcome to KG</h1>
      {/* <Carousel /> */}
      <Link to='/menu'>Online order</Link>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "24px",
        }}
      >
        <InstagramEmbed
          url='https://www.instagram.com/p/Cvj5gzeSWDL/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='
          width={328}
        />
      </div>
      <p>Explore our menu and place your order online.</p>
      <Routes>
        <Route to='/menu' Component={Menu}></Route>
      </Routes>
    </div>
  );
}

export default Home;
