import React, { useContext, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { ItemContext } from "../../context/ItemContext";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../style/order-card.css";

const OrderCard = () => {
  const { cart, totalPrice, setCart } = useContext(ItemContext);

  const removeFromCart = (itemId) => {
    console.log("Remove item", itemId);
    const updatedCart = cart.filter((item) => item.item.id !== itemId);
    console.log("updated cart", updatedCart);
    setCart(updatedCart);
  };

  useEffect(() => {}, [cart]);

  return (
    <Card sx={{ minWidth: 275, height: "25%" }}>
      <CardContent className='order_card'>
        <Typography variant='h10' component='div' gutterBottom>
          Cart
        </Typography>
        
        <ul>
          {cart.map((cartItem) => (
            <li
              key={cartItem.item.id}
              id={cartItem.item.id}
              className='option_list'
            >
             
              <p>Name: {cartItem.item.name}</p>
              <p>Quantity: {cartItem.item.quantity}</p>
              
              <ul className="order_options">
                {cartItem.item.options?.map((option) => (
                  <li key={option.id}>
                    Option: {option.name} + ${parseFloat(option.price) / 100}
                  </li>
                  
                ))}
                 <IconButton>
                  <DeleteIcon onClick={() => removeFromCart(cartItem.item.id)}  className="delete_icon"/>
                </IconButton> 
              </ul>
              <p>Price: ${cartItem.item.amount / 100}</p>
            </li>
          ))}
        </ul>

        <p>Total Price: ${totalPrice}</p>
      </CardContent>
    </Card>
  );
};

export default OrderCard;
